.d-flex-center{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.d-flex-center-center{
  display:flex;
  align-items: center;
  justify-content: center;
}
.d-flex-start{
  display:flex;
  align-items: center;
  justify-content: start;
}
.d-flex-end{
  display: flex;
  align-items: center;
  justify-content: end;
}
.p-color-green{
  color:rgb(113, 207, 98) !important;
  font-size: 13px;
}
.p-color-red {
  color: rgb(251,98,98) !important;
  font-size: 13px;
}
.text-center{
  text-align:center;
}
.border-red{
  border:2px solid rgb(251,98,98) !important;
}

.Modal-btn{
  font-size: 14px !important;
  line-height: 24px !important;
  padding: 10px 50px !important;
  margin-right: 20px !important;
  color:black;
  border:none;
  border-radius:10px;
}
.Modal-btn-red{
  background-color: #E33D58;
  color:white !important;
}


.frame {
  
  font-family: sans-serif;
  overflow: hidden;
 

  display: inline-block; 
  cursor: all-scroll
}


.zoom {
  font-size: 1.3vw;
  transition: transform 0.2s linear;
  cursor: all-scroll
}


img {  

}

.lorem {
  padding: 2% 2%;
}
.btn-dashboard-red-color{
  position:absolute !important;
  right:0px;
  top: 20px;
}
.logout-button-footer{

}